<template>
  <div>
    <p class="tips">{{ tips }}</p>
    <div v-if="!isSuccess" class="result-btn">
      <a-button type="primary" @click="handleAgain">
        再试一次
      </a-button>
    </div>
  </div>
</template>

<script>
import { getQueryString } from '@/utils/index.js'
import { getFaceCheck } from '@/api/insure.js'

export default {
  data() {
    return {
      tips: '',
      isSuccess: false, // 是否成功
      thirdOrderCode: '', // 订单号
      status: '' // 状态
    }
  },
  methods: {
    // 再试一次
    handleAgain() {
      const params = {
        thirdOrderCode: this.thirdOrderCode
      }
      getFaceCheck(params).then((res) => {
        if (res.status === 200) {
          if (res.data.status === 'FAIL') {
            this.$notification.error({
              title: '错误',
              message: res.data.errorMsg
            })
          } else {
            const url = res.data.data.url
            window.location.href=url
          }
        } else {
          this.$notification.warning({ message: res.data.message })
        }
      })
    },
    // 从url获取参数
    getQueryParams() {
      this.thirdOrderCode = getQueryString('thirdOrderCode')
      this.status = getQueryString('status')
      this.isSuccess = this.status === '20'
      this.tips =
        this.status === '20'
          ? '人脸识别成功，请返回PC端完成支付'
          : '人脸识别失败'
    }
  },
  mounted() {
    this.getQueryParams()
  }
}
</script>

<style lang="less" scoped>
.tips {
  margin-top: 120px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.result-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
}
</style>
